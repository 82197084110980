<template>
  <el-row class="movie-list">
    <el-col :md="24">
      <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <el-select v-model="selectedOption" placeholder="选择标签" @change="onChange">
              <el-option
                v-for="(item, index) in selectOptions"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
          <div class="text item amap-wrapper">
            <el-amap
              class="amap-box"
              :vid="'amap-vue'"
              :amap-manager="amapManager"
              :zoom="zoom"
              :center="mapCenter"
              :plugin="plugins"
              :events="mapEvents"
            >
              <el-amap-marker
                v-for="(marker, index) in markers"
                :key="index"
                :position="marker.position"
                :label="marker.label"
                :events="markerEvents"
                :ext-data="marker.extData"
              />
            </el-amap>
          </div>
        </el-card>
      </el-row>
    </el-col>

    <!-- marker 内容弹窗   -->
    <el-dialog
      append-to-body
      :visible.sync="showMarkerDialog"
      :before-close="handleDialogClose"
      width="30%"
      center
    >
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <el-button
            v-clipboard:copy="earthPoint.lat + ' ' + earthPoint.lng"
            v-clipboard:success="onCopy"
            v-clipboard:error="onCopyError"
            type="text"
          >GoogleEarth 坐标</el-button>
        </div>
        <div class="text item">
          <el-image v-if="markerInfo.photoUrl !== null" :src="markerInfo.photoUrl" min-width="40" height="30" />
          <br>
          <label>
            商品 <span v-text="markerInfo.itemId" />
          </label>
          <br>
          <label>
            SKU <span v-text="markerInfo.sku" />
          </label>
          <br>
          <label>
            评论 <span style="color: blue" v-text="markerInfo.replyContent" />
          </label>
          <br>
          <!--          <label v-if="markerInfo.appendContent !== undefined || markerInfo.appendContent !== null">
                      追加 <span style="color: blue" v-text="markerInfo.appendContent"/>
                    </label>-->
        </div>
      </el-card>
    </el-dialog>
  </el-row>
</template>

<script>
import { getGeoItems, getMapMarkers, getMarkerInfo } from '@/api/map'

import Vue from 'vue'
import VueAMap from 'vue-amap'
Vue.use(VueAMap)
VueAMap.initAMapApiLoader({
  key: 'your amap key',
  plugin: [
    'AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar',
    'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.MassMarks', 'AMap.Size',
    'AMap.Pixel'
  ],
  // 默认高德 sdk 版本为 1.4.4
  v: '1.4.15'
})

import { AMapManager, lazyAMapApiLoaderInstance } from 'vue-amap'
const amapManager = new AMapManager()

export default {
  name: 'PhotoMap',
  data() {
    return {
      amap: null,
      plugins: ['Scale'],
      mapCenter: [114.0000, 30.0000],
      // zoom=6 的比例尺为 100km
      zoom: 6,
      markers: [],
      markerEvents: {
        click: (e) => {
          const id = e.target.getExtData().id
          const position = e.target.getPosition()
          const item = {
            id: id,
            position: {
              lng: position.lng,
              lat: position.lat
            }
          }
          this.getMarkerInfoWrapper(item)
        }
      },
      /* 海量点 */
      massMarks: null,
      amapManager,
      mapEvents: {
        init: this.mapInit,
        zoomchange: this.zoomchange
      },
      /* 弹框数据 */
      showMarkerDialog: false,
      markerInfo: {
        itemId: null,
        sku: null,
        replyId: null,
        replyContent: null,
        appendContent: null,
        photoUrl: null
      },
      earthPoint: {
        lat: null,
        lng: null
      },
      selectedOption: 1,
      selectOptions: [
        { label: '全部', value: 1 }
      ],
      value: []
    }
  },
  created() {
    document.title = '照片地图'
    this.getItems()
  },
  methods: {
    getItems() {
      getGeoItems().then(resp => {
        if (resp.code === 0) {
          this.selectOptions = resp.data
        }
      })
    },
    mapInit(o) {
      this.amap = o
      lazyAMapApiLoaderInstance.load().then(() => {
        // 图标样式
        var styleObject = {
          url: '//webapi.amap.com/theme/v1.3/markers/n/mark_b.png', // 图标地址
          size: new AMap.Size(11, 11), // 图标大小
          anchor: new AMap.Pixel(5, 5) // 图标显示位置偏移量，基准点为图标左上角
        }

        // 海量点样式
        const massMarks = new AMap.MassMarks([], {
          zIndex: 5, // 海量点图层叠加的顺序
          zooms: [3, 10], // 在指定地图缩放级别范围内展示海量点图层
          style: styleObject // 设置样式对象
        })

        // 将海量标点添加至地图实例
        massMarks.setMap(o)
        // 添加点击事件
        massMarks.on('click', (e) => {
          console.log('massMark 点击事件')
        })
        this.massMarks = massMarks

        this.getMapMarkersWrapper(this.selectedOption)
        // this.getMapMarkersWrapper1(type)
      })
    },
    zoomchange(e) {
      console.log('当前缩放级别: ' + this.amap.getZoom())
    },
    getMapMarkersWrapper(type) {
      this.markers = []
      getMapMarkers(type).then(res => {
        if (res.code === 0) {
          this.$notify({
            message: '加载了 ' + res.data.length + ' 条数据',
            type: 'warning',
            duration: 5000
          })

          for (const item of res.data) {
            this.markers.push({
              position: [item.position.lng, item.position.lat],
              label: { content: item.title, offset: [0, 0] },
              extData: { id: item.id }
            })
          }
        } else {
          this.$notify({
            message: res.msg,
            type: 'warning',
            duration: 3000
          })
        }
      }).catch(error => {
        this.$notify({
          message: error.message,
          type: 'warning',
          duration: 3000
        })
      })
    },
    // 渲染海量点
    getMapMarkersWrapper1(type) {
      this.massMarks.setData([])
      getMapMarkers(type).then(res => {
        if (res.code === 0) {
          this.$notify({
            message: '加载了 ' + res.data.length + ' 条数据',
            type: 'warning',
            duration: 5000
          })

          const massMarkers = []
          for (const item of res.data) {
            massMarkers.push({
              lnglat: [item.position.lng, item.position.lat],
              id: item.id
            })
          }
          this.massMarks.setData(massMarkers)
          this.zoom = 5
        } else {
          this.$notify({
            message: res.msg,
            type: 'warning',
            duration: 3000
          })
        }
      }).catch(error => {
        this.$notify({
          message: error.message,
          type: 'warning',
          duration: 3000
        })
      })
    },
    getMarkerInfoWrapper(item) {
      this.earthPoint = item.position
      this.showMarkerDialog = true
      this.markerInfo = {
        itemId: null,
        sku: null,
        replyId: null,
        replyContent: null,
        appendContent: null,
        photoUrl: null
      }

      getMarkerInfo(item.id).then(res => {
        if (res.code === 0) {
          this.markerInfo = res.data
        } else {
          this.showMarkerDialog = false
        }
      }).catch(error => {
        this.showMarkerDialog = false
        console.log(error)
      })
    },
    /* 弹出框 */
    handleDialogClose(done) {
      this.showMarkerDialog = false
      this.earthPoint = { lat: null, lng: null }
      done()
    },
    onCopy(e) {
      this.$notify({
        message: 'GoogleEarth 坐标已复制, 快到 GoogleEarth 中去找这个地方吧~',
        type: 'warning',
        duration: 3000
      })
    },
    onCopyError(e) {
      this.$notify({
        message: '复制 GoogleEarth 坐标失败!',
        type: 'error',
        duration: 3000
      })
    },
    onChange() {
      this.getMapMarkersWrapper(this.selectedOption)
    }
  }
}
</script>

<style>
.amap-wrapper {
  width: 100%;
  height: 600px;
}

.movie-list {
  padding-top: 15px;
  padding-left: 3%;
  padding-right: 3%;
}
</style>
